<template lang="pug">
  .benefits
    .benefits__container
      h2.title.benefits__title
        | ПРО DRIVELINK
      .benefits__text
        p
          | Тепер навіть у самих віддалених та важкодоступних місцях Ви зможете залишатись на зв'язку.
          | Замовляйте модифіковану антену Starlink для свого підрозділу та отримайте високошвидкісний доступ до мережі Інтернет, де б Ви не були.
        p
          | Ми пропонуємо готове рішення або можемо покращити Ваш наявний Starlink, перетворивши його антену на портативний модуль для монтажу на дах автомобіля.
      ul.benefits__list
        li(v-for="(item, index) in benefitsItems" :key="index").benefits__item
          .benefits__img
            v-img(:src="item.imageSrc", :alt="item.title")
          h3.benefits__subtitle {{ item.title }}
          .text.benefits__desc
            p {{ item.description }}
</template>

<script>
export default {
  name: 'BenefitsBlock',
  data () {
    return {
      benefitsItems: [
        {
          imageSrc: require('@/assets/images/benefits/01.svg'),
          title: 'Проста підготовка для використання',
          description:
            'Головна перевага модифікованого Drivelink в швидкості розгортання системи супутникового звʼязку, яка виключає потребу плоскої нерухомої поверхні для налаштування терміналу. Він одразу знаходиться в максимально вигідному положенні для прийому сигналу з робочим кутом 100°.'
        },
        {
          imageSrc: require('@/assets/images/benefits/02.svg'),
          title: 'Швидкість Інтернет-підключення',
          description:
            'При вдосконаленні стандартного Starlink, термінал не втрачає швидкості передачі даних. Результати реальних випробувань показали стабільну швидкість (130-150 Мбіт/cек) з мінімальними затримками (60-70 мс.). Зв’язок зберігається навіть на великих швидкостях авто. Якість зʼєднання залежить від погодних умов та фізичних перепон між терміналом і супутником.'
        },
        {
          imageSrc: require('@/assets/images/benefits/03.svg'),
          title: 'Особливості підключення і живлення',
          description:
            'Термінал Drivelink встановлюється на даху авто і підключається кабелем до роутера в машині, який може живитись від інвертора. Автомобільний інвертор можна підключити до прикурювача на 12V, що дозволить отримати безперервне живлення для постійного зв’язку. При стаціонарному розташуванні підрозділу, для живлення можна використовувати генератор.'
        },
        {
          imageSrc: require('@/assets/images/benefits/04.svg'),
          title: 'Захищений звʼязок у найвіддаленіших регіонах',
          description:
            'Підключення не залежить від покриття вишок мобільного звʼязку і надає доступ до мережі Інтернет всюди, де інші технології недоступні. Термінали Stralink забезпечують шифрування даних та безпечний канал зв\'язку, що робить їх ідеальними для використання в умовах бойових дій.'
        }
      ]
    }
  }
}
</script>
